.contact-section{
    padding-top: 100px;
    background-color: white;


    .card-holder{
        min-height: 200px;
    }

    .search-section{
        .heading-search{
            font-size: 31px;
            line-height: 50px;
            text-align: center;
            color: #555555;
        }
        .search-input{
            height: 60px;
            text-align: left;
            font-size: 16px;
            border: 1px solid rgba(0,0,0,0.2);
        }

        .search-input:focus{
            box-shadow: 0px 3px 10px rgba(0,0,0,0.05);
            outline: none;
        }
    }

    .topic-index-header{
        padding: 20px;
        border-bottom: 1px solid rgba(0,0,0,0.08);
        .product-heading-section{
            font-size: 18px;
            color: #555555;
        }
        .article-count{
            font-size: 16px;
            color: #555555;
        }
    }

    .product-section-list{
        ul{
            list-style-type: none;
            padding: 0%;
            margin-bottom: 0%;
            li{
                padding: 12px 20px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.02);
                a{
                    color: #088aab;
                    font-size: 16px;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
            li:hover{
                background: rgba(250, 250, 250, 0.8);
            }
        }
    }

    .view-more-section{
        padding: 20px;
        a{
            text-decoration: none;
            color: #999;
        }
    }

    .contact-us-bottom{
        color: #555555;
        font-size: 16px;
    }

    .contact-para{
        color: #555555;
        .link-contact-us{
            color: #088aab;
        }
    }
}