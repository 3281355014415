.product-wrapper {
  background-color: #0c1317 !important;
  // margin-bottom: 80px;
  // margin-top: 120px;
  //banner section

  .banner-wrapper {
    // margin-top: 30px;
    position: relative;
    top: 166px;

    .best-seller-btn {
      font-family: "sf-pro" !important;
      background-color: #e73d51;
      text-align: center;
      letter-spacing: 1.1px;
      padding: 6px 16px;
      color: #fff;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 500;
      margin: 20px;
      outline: none;
      border: none;
    }

    .play-section {
      background-color: #fff;
      border-radius: 30px;
      cursor: pointer;

      p {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        text-align: center;
      }

      .vedio-play-icon {
        background-image: url("/assets/images/new-image/Play-icon.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 20px;
        height: 20px;
      }
    }

    .play-section:hover {
      background-color: rgba(255, 255, 255, 0.2);

      p {
        color: #fff !important;
      }

      .vedio-play-icon {
        background-image: url("/assets/images/new-image/Play-white-icon.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 20px;
        height: 20px;
      }
    }
    .card-img-top {
      width: 100%;
      height: 625px;
    }

    .add-to-cart-btn {
      border-radius: 20px;
      background-color: #0071de;
      font-size: 16px;
      height: 40px;
      font-family: "sf-pro";
      font-weight: 510;
    } 

    .add-to-cart-btn:hover {
      background-color: #0067cb;
    }

    .view-sample {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
    }

    .hsa-detail {
      background-color: #fff;
      border-radius: 30px;
      padding: 5px 10px;
      min-width: 380px !important;
      outline: none;
      border: none;
    }

    .hsa-detail-holder {
      background-color: #fff;
      border-radius: 30px;
      padding: 5px 10px;
      min-width: 208px !important;
      outline: none;
      border: none;

      .hsa-section {
        font-family: "Outfit" !important;
        .hsa-cap {
          font-size: 24px;
        }
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      }
    }

    .product-banner-detail {
      .product-name {
        font-size: 45px;
        font-weight: 700;
        line-height: 48.72px;
        text-align: left;
      }

      .price-detail {
        font-size: 32px;
        font-weight: 700;
        line-height: 22px;
      }
      .saveValue{
        color: rgb(248, 247, 244);
        font-size: 14px;
        font-weight: 400;
        height: 22px;
        padding-left: 8px;
        padding-right: 8px;
        display: flex;
        align-items: center;
      }
      .current-price{
        color: #F8F7F4;
        font-size: 24px;
        font-weight: 400;
        line-height: 20px;
        padding-left: 5px;
      }
      .compareat-price{
        padding-left: 10px;
        font-size: 24px;
        font-weight: 400;
        line-height: 20px;
        color: #848484;
        text-decoration: line-through;
        margin: 0;
      }

      .product-sub-title {
        font-size: 18px;
        font-weight: 590;
        line-height: 22px;
        text-align: left;
      }

      .review-count {
        font-size: 16px;
        font-weight: 400;
        line-height: 20.16px;
        text-align: left;
      }

      .banner-product-description {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
      }

      .faq-section {
        border-bottom: 1px solid #848484;
        .product-accordion-text{
        font-weight:bold;
        font-family: "sf-pro";
        } 
        // .accordion-collapse {
        //   max-height: 0;
        //   overflow: hidden;
        //   transition: max-height 5s ease-out;
        // }
        
        // .accordion-collapse.show {
        //   max-height: 1000px; /* Adjust this value if necessary */
        // }
        
      }
    }
  }

  //banner section max-width 475px

  @media only screen and (max-width: 576px) {
    .banner-wrapper {
      // margin-top: 0px;
      position: relative;
      top: 20px;

      .hsa-detail {
        min-width: 0px !important;
        width: 100% !important;
      }

      .img-container {
        padding: 0%;
      }

      .product-banner-detail {
        padding: 32px 24px;

        .product-name {
          font-size: 32px;
        }

        .price-detail {
          font-size: 24px;
        }

        .product-sub-title {
          font-size: 16px;
          font-weight: 274;
        }

        .review-count {
          font-size: 16px;
          font-weight: 400;
          line-height: 20.16px;
          text-align: left;
        }

        .banner-product-description {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
        }
      }

      .card-img-top {
        width: 100%;
        height: 410px;
      }

      .hsa-img {
        width: 100%;
      }
    }
  }
  // @media only screen and (max-width: 400px) {
  //   .banner-wrapper {
  //     .product-banner-detail {
  //       padding: 10px 5px;
  //     }
  //   }
  // }
  //vedio section

  .youtube-video-holder {
    margin: 124px 0px;
  }

  //detail section

  .detail-container {
    margin-top: 225px;
    .detail-heading {
      font-size: 34px;
      font-weight: 700;
      line-height: 46px;
      text-align: left;
    }

    .description-details {
      color: #f8f7f4;
      font-size: 16px;
      font-weight: 274;
      line-height: 25.6px;
      text-align: left;
    }

    .details-title {
      font-size: 18px;
      font-weight: 590;
      line-height: 22px;
      text-align: left;
    }
  }

  //detail section max-width 475px

  @media only screen and (max-width: 475px) {
    .detail-container {
      margin-top: 50px;
      .detail-section {
        padding: 24px 32px;
        .detail-heading {
          font-size: 26px;
        }

        .description-details {
          font-size: 16px;
        }

        .details-title {
          font-size: 18px;
        }
      }
    }
  }

  //Product section section in set

  .product-section-holder {
    padding: 64px 0px;

    .product-title {
      font-size: 45px;
      font-weight: 700;
      line-height: 57px;
    }
    .product-detail-holder {
      width: 750px;

      .tags {
        font-size: 20px;
        font-weight: 510;
        line-height: 57px;
      }

      .product-description {
        font-size: 16px;
        color: #717171;
      }
    }
  }

  //Product section section in set max-width 475px

  @media only screen and (max-width: 475px) {
    .product-section-holder {
      padding: 20px 0px;

      .product-title {
        font-weight: 400;
      }

      .product-detail-holder {
        width: 100%;

        .tags {
          font-size: 16px;
        }

        .product-description-sec {
          padding: 34px 24px;
        }

        img {
          width: 100%;
        }
      }
    }
  }
  @media only screen and (max-width: 400px) {
    .product-section-holder {
      padding: 20px 0px;

      .product-title {
        font-weight: 400;
      }

      .product-detail-holder {
        width: 100%;

        .tags {
          font-size: 13px;
        }

        .product-description-sec {
          padding: 34px 24px;
        }

        img {
          width: 100%;
        }
      }
    }
  }
  //set page health By effectively  section

  .health-holder {
    padding: 64px 400px;

    .health-container {
      border-radius: 20px;

      p {
        font-size: 20px;
      }
    }
  }

  // review-section

  .review-wrapper {
    .review-title {
      font-size: 34px;
      font-weight: 700;
      line-height: 46px;
      letter-spacing: 0.1em;
    }
    .rasio {
      font-size: 32px;
      font-weight: 510;
      line-height: 30px;
      letter-spacing: 0.1em;
    }
    .review-no {
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
    }

    .reviewer-section {
      .reviewer-name {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
      }
      .reviewer-status {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
        color: #34a853;
      }
      .date {
        color: rgba(112, 112, 112, 1);
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
      }
      .reviewer-rating-points {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
        text-align: left;
      }
    }

    .reviewer-title {
      font-size: 20px;
      font-weight: 510;
      line-height: 24px;
      text-align: left;
    }

    .review-description {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }

    .thumbs {
      font-size: 16px;
      font-weight: 510;
      line-height: 19.09px;
      text-align: center;
      margin: 0;
    }

    .bar-section {
      min-width: 150px;

      .star-val {
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
        color: #fff;
      }

      .percentage-rating {
        color: #fff;
      }

      .progress-section-one {
        height: 8px;
        width: 150px;
      }

      .progress-section-two {
        height: 8px;
        width: 120px;
      }

      .progress-section-three {
        height: 8px;
        width: 90px;
      }

      .progress-section-four {
        height: 8px;
        width: 60px;
      }

      .progress-section-five {
        height: 8px;
        width: 40px;
      }

      .star-val {
        font-size: 14px;
        font-weight: 300;
      }

      .percentage-rating {
        font-size: 20px;
        font-weight: 300;
      }

      .percentage-rating {
        font-size: 14px;
      }
    }

    .review-search {
      outline: none; /* Optional: remove default outline */
      border: none;
      box-shadow: none;
      color: #fff; /* Optional: remove any box-shadow */
    }

    .sorting {
      border: white;
      border-radius: 20px;
      background-color: rgba(196, 196, 196, 0.2);
      border: 0.67px solid rgba(220, 223, 226, 1);

      option {
        background-color: rgba(196, 196, 196, 0.2);
        border: 0.67px solid rgba(220, 223, 226, 1);
      }
    }

    .review-btn {
      color: #fff;
      border: 1px solid rgba(248, 247, 244, 1);
      border-radius: 20px;
    }

    .view-more {
      background-color: rgba(1, 113, 222, 1);
      border-radius: 20px;
      font-size: 16px;
      font-weight: 510;
      line-height: 20px;
      text-align: center;
      color: white;
      padding: 12px 20px;
      border: none;
    }
  }

  // review-section max-width 475px
  @media only screen and (max-width: 475px) {
    .review-wrapper {
      padding: 24px 34px;

      .review-post-img {
        width: 100%;
      }
    }
  }

  .paperback-btn {
    background-color: transparent;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 510;
    color: rgba(248, 247, 244, 1);
    line-height: 20px;
    padding: 10px 0px;
    border: 0.5px solid rgba(248, 247, 244, 1);
  }
  .paperback-btn:hover {
    border: 0.5px solid rgba(248, 247, 244, 1);
    background-color: rgba(248, 247, 244, 0.15);
  }
  .paperback-btn.active {
    border: 0.5px solid rgba(248, 247, 244, 1);
    background-color: rgba(248, 247, 244, 0.15);
  }
  // faq-wrapper

  .Faq-wrapper {
    padding: 120px 0px;

    .faq-section {

      .faq-heading {
        font-size: 34px;
        font-weight: 700;
        line-height: 46px;
        letter-spacing: 0.1em;
        text-align: left;
      }

      .faq-question {
        // font-size: 20px;
        // font-weight: 400;
        line-height: 30px;
        text-align: left;

        font-family: "sf-pro";
        font-weight:500;
        font-size: 16px;
      }

      .faq-answer {
        p {
          font-size: 14px;
          font-weight: 274;
          line-height: 16px;
          letter-spacing: 0.1em;
          
          font-family: "sf-pro";
        }
      }
    }

    .accordion-button:focus {
      background-color: transparent !important; /* Prevent background color change on hover/focus */
      color: white !important; /* Prevent text color change on hover/focus */
      box-shadow: none !important; /* Remove any focus shadow */
    }

    .custom-opacity {
      opacity: 1; /* Fully opaque */
      color: #848484 !important;
    }
  }

  // faq-wrapper max-width 475px
  .accordion-header {
    border-top: 1px solid  #848484 !important;
    /* Green border on top of header */
  }
  @media only screen and (max-width: 475px) {
    .Faq-wrapper {
      padding: 0px;

      .faq-section {
        padding: 0px 31.5px;

        .faq-heading {
          font-size: 24px;
        }

        .faq-question {
          font-size: 14px;
        }
      }

      .custom-opacity {
        opacity: 1; /* Fully opaque */
        color: #848484 !important;
      }
    }
  }

  // restore-realign-wrapper

  .restore-realign-wrapper {
    p {
      font-size: 34px;
      font-weight: 700;
      line-height: 46px;
      text-align: center;
    }
  }

  // restore-realign-wrapper max-width 475px

  @media only screen and (max-width: 475px) {
    .restore-realign-wrapper {
      p {
        font-size: 24px;
        line-height: 26px;
      }
    }
  }

  // card section

  .card-section {
    .card:hover {
      .card-title {
        color: #0171de !important;
      }
    }

    .card {
      background-color: transparent;
      width: 366px;

      .card-title {
        font-size: 22px;
        font-weight: 590;
        line-height: 20px;
      }

      .card-title:hover {
        color: #0171de !important;
      }
      .card-title.active {
        color: #0171de !important;
      }

      .card-body {
        p {
          font-size: 16px;
          font-weight: 400;
        }
      }

      img {
        border-radius: 15px;
      }
      .card-hover {
        overflow: hidden; /* To ensure the image doesn't overflow the card */
      }

      .card-hover img {
        transition: transform 0.3s ease; /* Smooth zoom transition */
      }

      .card-hover:hover img {
        transform: scale(1.1); /* Zoom the image by 10% */
      }
      .card-hover.active img {
        transform: scale(1.1); /* Zoom the image by 10% when the card is active */
      }
      
      /* Optional: Highlight the entire card when active */
      .card.active-card {
        border: 2px solid #D41C2C; /* For example, add a red border on active card */
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
      }
    }
  }

  // card section max-width 475px

  @media only screen and (max-width: 475px) {
    .card-section {
      .card {
        .card-title {
          font-size: 18px;
        }
        .card-body {
          p {
            font-size: 12px;
          }
        }
      }
    }
  }

  // mark product section

  .mark-product-wrapper {
    padding: 60px 0px;

    .sub-title {
      font-size: 22px;
      font-weight: 700;
      line-height: 22px;
      text-transform: capitalize;
    }
    .description {
      font-size: 18px;
      font-weight: 274;
      line-height: 22px;
    }

    .Engineered-section {
      .engineered-heading {
        font-size: 34px;
        font-weight: 700;
        line-height: 46px;
        text-align: center;
      }
      .engineered-heading-mobile {
        font-size: 24px;
      }
    }
  }

  // mark product section max-width 475px

  @media only screen and (max-width: 475px) {
    .mark-product-wrapper {
      .sub-title {
        font-size: 12px;
        font-weight: 510;
        line-height: 12px;
      }
      .description {
        font-size: 12px;
      }
    }
  }

  // watch vedio section

  .watch-vedio-wrapper {
    .watch-vedio-btn {
      width: 388px;
      text-align: center;
      border: 1px solid rgba(248, 247, 244, 1);
      background-color: rgba(248, 247, 244, 0.1);
      border-radius: 20px;
      color: #fff;
      height: 40px;
    }

    .watch-vedio-img {
      width: 100% !important;
    }
  }

  @media only screen and (max-width: 475px) {
    .watch-vedio-wrapper {
      .watch-vedio-btn {
        width: 75%;
      }
    }
  }

  .watch-vedio-holder {
    .faq-section .faq-question {
      font-size: 16px;
      font-weight: 590;
      line-height: 22px;
      text-align: left;
    }
  }

  // range section holder

  .range-sections-holder {
    .range-product-title {
      font-size: 22px;
      font-weight: 510;
      line-height: 22px;
      text-align: center;
    }

    .range-product-description {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
    }
  }

  @media only screen and (max-width: 475px) {
    .range-sections-holder {
      .range-product-title {
        font-size: 12px;
      }

      .range-product-description {
        font-size: 12px;
        font-weight: 274;
        line-height: 14px;
        text-align: center;
      }
    }
  }

  // common for all

  .img-arrow-right,
  .img-arrow-left {
    cursor: pointer;
  }
  // .accordion-collapse {
  //   transition: max-height 0.5s ease-out;
  //   overflow: hidden;
  // }
  .accordion-button::after {
    background-image: url("/assets/images/new-image/faq-dropdown-arrowdown.png") !important;
  }

  .accordion-button:focus {
    background-color: transparent !important; /* Prevent background color change on hover/focus */
    color: white !important; /* Prevent text color change on hover/focus */
    box-shadow: none !important; /* Remove any focus shadow */
  }

  .accordion-button:not(.collapsed) {
    box-shadow: none !important;
  }
  .custom-opacity {
    opacity: 1; /* Fully opaque */
    color: #848484 !important;
  }

  .search-review-holder {
    background-color: rgba(196, 196, 196, 0.2);
    border: 0.67px solid rgba(220, 223, 226, 1);
    border-radius: 20px;

    /* Ensure no background on focus */
    input[type="search"]:focus {
      background: none;
      outline: none; /* Optional: remove default outline */
      box-shadow: none; /* Optional: remove any box-shadow */
    }

    input {
      background: none;
      outline: none; /* Optional: remove default outline */
      box-shadow: none; /* Optional: remove any box-shadow */
    }

    .review-search:focus {
      outline: none;
    }
  }
}

.brand-section {
  padding: 120px 0px;

  .brand-img-section {
    border: none !important;
    border-bottom: none !important;

    .brands-img {
      width: auto !important;
      // height: auto !important;
    }
  }
}

@media only screen and (max-width: 475px) {
  .product-wrapper {
    margin-top: 120px;
  }
  .brand-section {
    padding: 80px 0px;
  }
}

.watch-vedio-top-section {
  color: #f8f7f4;
  h2 {
    font-size: 45px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
  }
  h6 {
    font-size: 16px;
    font-weight: 274;
  }
}

@media only screen and (max-width: 475px) {
  .watch-vedio-top-section {
    h2 {
      font-size: 24px;
    }
    h6 {
      font-size: 12px;
    }
  }
}

.accordion-collapse {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease-out, padding 0.6s ease-out;  /* Increased duration */
}

.accordion-collapse.show {
  max-height: 1000px;  /* Arbitrary large value */
  // padding: 15px;     /* Adjust padding to match content */
  transition: max-height 0.6s ease-in, padding 0.6s ease-in;
}

.accordion-button {
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  padding: 15px;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;  /* Button hover effect */
}

.accordion-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.accordion-button.collapsed {
  background-color: rgba(0, 0, 0, 0.1);
}

.accordion-body {
  // padding: 0 15px;
  color: white;
  font-size: 14px;
}
@media only screen and (max-width: 992px) {
  .product-wrapper .banner-wrapper .product-banner-detail .saveValue{
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 13px;
    font-weight: 510;
    line-height: 12px;
  }
  .product-wrapper .banner-wrapper .product-banner-detail .current-price{
    font-size: 18px;
  }
  .product-wrapper .banner-wrapper .product-banner-detail .compareat-price{
    font-size: 18px;
  }
} 

@media only screen and (max-width: 600px) {
  .product-wrapper{
  .product-section-holder {
    padding: 20px 0px;

    .product-title {
      font-weight: 400;
    }

    .product-detail-holder {
      width: 100%;

      .tags {
        font-size: 16px;
      }

      .product-description-sec {
        padding: 34px 24px;
      }

      img {
        width: 100%;
      }
    }
  }
}
}

