@font-face {
  font-family: "sf-pro";
  src: url("/assets/fonts/SF-Pro-Display-Regular.otf") format("opentype");
  /* Regular */
  font-weight: normal;
  /* Default to regular weight */
}

@font-face {
  font-family: "sf-pro";
  src: url("/assets/fonts/SF-Pro-Display-Bold.otf") format("opentype");
  /* Bold */
  font-weight: bold;
}

@font-face {
  font-family: "sf-pro";
  src: url("/assets/fonts/SF-Pro-Display-Medium.otf") format("opentype");
  /* Medium */
  font-weight: 500;
  /* Medium */
}

@font-face {
  font-family: "sf-pro";
  src: url("/assets/fonts/SF-Pro-Display-Thin.otf") format("opentype");
  /* Thin */
  font-weight: 100;
  /* Thin */
}

@font-face {
  font-family: "sf-pro";
  src: url("/assets/fonts/SF-Pro-Display-Light.otf") format("opentype");
  /* Light */
  font-weight: 300;
  /* Light */
}

@import "./variables.scss";

@import "./header.scss";
@import "./footer.scss";
@import "./home.scss";

// @import './shop.scss';
@import "./learn.scss";
@import "./how-to-use.scss";
@import "./about.scss";
// @import './body-pain.scss';
// @import './book.scss';
// @import './story.scss';
// @import './contact.scss';
@import "./cart.scss";
// @import './checkout.scss';
@import "./set.scss";
@import "./popup.scss";
@import "./contact-us.scss";
@import "./patent.scss";

@import "./product.scss";
@import "./manuals.scss";
@import "./policy.scss";
@import "./terms.scss";
@import "./progressbar.scss";
@import "./stampedreviews.scss";
@import "./Frontrowwidget.scss";
@import "./affiliateForm.scss";
@import "klaviyosignup.scss";


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background: #0C1317 !important;
}

:root {
  --border-radius: 12px;

  --foreground-rgb: 0, 0, 0;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

.announcement-text{
  font-size: 14px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  position: relative;
}

body {
  font-family: $sfProFont !important;
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}

.slick-dots li button:before {
  content: "" !important;
}

.c-modal {
  z-index: 1000;

  .c-modal-backdrop {
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .c-modal-content {
    width: 700px;
    height: 600px;
    border-radius: 25px;
    position: relative;
    .modal-close {
      position: absolute;
      top: 5px;
      right: 5px;
    }
    .v-content {
      width: 100%;

      .v-iframe {
        width: 100%;
        height: 500px;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
      }

      .v-det {
        padding: 10px 10px;
        h1 {
          font-weight: bold;
          font-size: 0.85rem;
        }
        p {
          font-weight: bold;
          font-size: 0.8rem;
          padding-right: 30px;
        }
        .v-desc {
          font-weight: 400;
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          // -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .v-recommended {
          color: $blueColor;
          text-decoration: underline;
          font-size: 0.85rem;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .c-modal-content {
    width: 90% !important;
    height: 500px !important;
    .v-iframe {
      height: 350px !important;
    }
  }
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.slick-prev,
.slick-next {
  display: none !important;
}

.product-arrows-container {
  display: flex;
  flex-direction: row; // Align items in a row
  row-gap: 15px;
  align-items: center; // Center items vertically
  width: 100%; // Full width of the parent
  position: relative; // Relative positioning for absolute children if needed
}

.product-arrow {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  // padding: 2px;
  background-color: transparent; // Transparent background
  border: 1px solid white; // 2px white border  
  border-radius: 50%; // Circular border
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    color: white;
    font-size: 20px;
    font-style: bold;
  }
}

// Specific styles for PrevArrow
.product-prev {
  left: 12px; // Specific position for PrevArrow
}

// Specific styles for NextArrow
.product-next {
  right: 12px; // Specific position for NextArrow
}

.arrow-button {
  display: flex; /* Align items in the center */
  align-items: center; /* Center align the content */
  justify-content: center; /* Center align the content */
  cursor: pointer; /* Change cursor to pointer */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}

.arrow-button:hover {
  background-color: white; /* Background color on hover */
}

.arrow-button span {
  color: white; /* Initial text color */
  transition: color 0.3s; /* Smooth transition for text color */
}

.arrow-button:hover span {
  color: black; /* Text color on hover */
}

.custom-modal .modal-dialog {
  max-width: 654px;

}

.custom-model-head {
  background-color: #f6f6f6;
}
.custom-modal-head .btn-close {
  background-color: black; /* Set background to black */
  opacity: 1; /* Ensures the black color is solid */
  font-weight: bold !important;
  font-size: 1.5rem !important;
}
.custom-modal-head .btn-close::after {
  content: "×"; /* Override the default 'X' */
  font-weight: bold !important; /* Make the 'X' bold */
  font-size: 1.5rem !important; /* Increase size if needed */
  color: white; /* Make sure the 'X' is visible with a white color */
}
// @keyframes slideInFromRight {
//   0% {
//     transform: translateX(100%);
//     opacity: 0;
//   }
//   100% {
//     transform: translateX(0);
//     opacity: 1;
//   }
// }

// .custom-modal {
//   animation: slideInFromRight 0.5s ease-out forwards;
// }

// .custom-modal .modal-dialog {
//   transform: translateX(0); /* Ensure dialog starts centered */
//   transition: transform 0.5s ease-out;
// }
// @keyframes slideInFromLeft {
//   0% {
//     transform: translateX(-100%); /* Start from the left */
//     opacity: 0;
//   }
//   100% {
//     transform: translateX(0); /* Move to the center */
//     opacity: 1;
//   }
// }

// .custom-modal {
//   animation: slideInFromLeft 0.5s ease-out forwards;
// }

// .custom-modal .modal-dialog {
//   transform: translateX(0); /* Ensure dialog starts centered */
//   transition: transform 0.5s ease-out;
// }

@keyframes zoomInFromTopRight {
  0% {
    transform: translate(120%, -30%) scale(0.5); /* Start from the top-right corner */
    opacity: 0;
  }
  100% {
    transform: translate(0, 0) scale(1); /* Move to the center with normal size */
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    right: -300px;
  }
  100% {
    right: 0;
  }
}

.cart-modal {
  transition: right 0.5s ease-out;
  max-width: 654px; // copying .custom-modal .modal-dialog to keep consistent with other modals
  padding: 135px 0 10px 0;
  
  .modal-content {
    overflow-y: auto !important;
    height: calc(100vh - 142px);  
  }

  &.cart-modal-open {
    animation: slideInFromRight 0.5s ease-out forwards;
  }
}

@media (min-width: 576px) {
  .cart-modal {
    margin: 0 5px 0 auto !important;
    padding: 168px 0 10px 0 !important;

    .modal-content {
      height: calc(100vh - 168px) !important;  
    }
  }
}

/* Apply the animation to the modal dialog */
.custom-modal {
  animation: zoomInFromTopRight 0.6s ease-out forwards;
}

.custom-modal .modal-dialog {
  transform: translate(0, 0); /* Ensure the dialog starts centered */
  transition: transform 0.5s ease-out;
}









