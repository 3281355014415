.shipping-progress-bar-container {
    background-color:  #0171DE26; 
    padding: 16px;
    text-align: center;
    border-radius: 8px;
    margin-bottom: 10px;
    position: relative;  
  }
  
  .shipping-progress-message {
    font-size: 16px;
    margin-bottom: 10px;
    color: black; 
}

.bold-text {
  font-weight: bold; 
}
  
  .shipping-progress-bar {
    position: relative;
    width: 100%;
    height: 8px;
    background-color: #d1d1d1; 
    border-radius: 4px;
    overflow: hidden;
   
  }
  
  .progress-bar-fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #0171DE; 
    transition: width 0.5s ease-out;
  }
  .progress-bar-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #d1d1d1;
  }
  
  .progress-bar-amounts {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    font-size: 14px;

  }
  
  .subtotal, .free-shipping {
    font-weight: bold;
  }
  