.use-toggle-wrapper {
  padding-top: 100px;
  margin-top: 130px;

  .user-manual-btn {
    font-size: 18px;
  }

  .search-filters {
    --bs-list-group-border-color: transparent;

    .toggle-btn {
        color: #ffffff;
        background-color: #151515;
        border-radius: 25px;
        border: 0px solid rgba(0, 0, 0, 1);
        padding: 9px 30px;
        margin: 0px 0px 10px 0px;
      }
      
      .toggle-btn:hover {
        background-color: #2b2b2b;
        color: white;
      }
      
      .toggle-btn.active {
        background-color: #2b2b2b; /* Apply the active background color */
        color: white; /* Apply the active text color */
        border: none; /* Remove border if desired */
      }
  }

  .vedio-title {
    font-size: 14px;
  }

  .card {
    background-color: #242424;
  }
}

.wrapper-how-to{
  background-color: #000000;
}

@media only screen and (max-width: 475px) {
  .use-toggle-wrapper {
    padding-top: 40px;
    margin-top: 130px;
    .card{
      height: 250px;
    }

    .user-manual-btn {
      border: 1px solid #d9d9d9;
      padding: 10px 20px;
      font-size: 14px;

      img {
        width: 26px;
        height: 30px;
      }
    }
  }
}
