.policy-wrapper {
  padding-top: 105px;
  // margin-top: 110px;
  position: relative;
  top: 80px;
}
@media only screen and (max-width: 475px) {
  .policy-wrapper {
    padding-top:85px;
    // margin-top: 90px;
    position: relative;
    top: 80px;
  }
}
