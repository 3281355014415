.terms-wrapper {
  .small-heading-1 {
    color: #ffffff;
    text-align: center;
    background-color: rgba(255, 255, 255, 0);
    font-family: Roboto, sans-serif;
    font-size: 29px;
    font-weight: 700;
    line-height: 34px;
  }

  .main__description.dark-small {
    max-width: none;
    padding-top: 0;
    font-size: 22px;
    line-height: 28px;
  }

  strong {
    font-weight: bolder;
  }
}

/* For medium-sized devices (tablets) */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .terms-wrapper {
    .small-heading-1 {
      font-size: 24px; /* Reduced from 29px */
      line-height: 30px;
    }

    .main__description.dark-small {
      font-size: 18px; /* Reduced from 22px */
      line-height: 24px;
    }
  }
}

/* For mobile devices (phones) */
@media screen and (max-width: 767px) {
  .terms-wrapper {
    .small-heading-1 {
      font-size: 20px; /* Further reduced for smaller screens */
      line-height: 26px;
    }

    .main__description.dark-small {
      font-size: 16px; /* Further reduced for smaller screens */
      line-height: 22px;
    }
  }
}
