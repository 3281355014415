.affilates{
// margin-top: 115px;
position: relative;
}
.block-container
{
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.block-article{
background-color: #000;
padding: 100px 5%;
font-family: Sf Pro, sans-serif;
font-size: 16px;
line-height: 22px;
display: block;
box-sizing: border-box;
}
.set-color{
    color: #fff;
}
.grid-layout{
    grid-column-gap: 50px;
    grid-template-rows: auto;
    margin-bottom: 50px;
    font-family: Sf Pro, sans-serif;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
    grid-row-gap: 16px;
    margin-top: 70px;
}
.form-wrapper{
    color: #fff;
    font-family: Sf Pro, sans-serif;
    font-size: 16px;
    line-height: 22px;
}
.input-field{
    color: #727272;
    letter-spacing: -.8px;
    background-color: #0000;
    border: 1px #000;
    border-bottom: 1px solid #6b6b6b;
    height: 55px;
    margin-bottom: 30px;
    padding: 12px 0;
    font-family: Sf Pro, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    box-shadow: 1px 1px 3px #0000000a;
    display: block;
    width: 100%;
}
.textarea-field{
    color: #727272;
    letter-spacing: -.8px;
    background-color: #0000;
    border: 1px #000;
    border-bottom: 1px solid #6b6b6b;
    height: auto;
    margin-bottom: 30px;
    padding: 12px 0;
    font-family: Sf Pro, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    box-shadow: 1px 1px 3px #0000000a;
    display: block;
    width: 100%;
}
.form-button{
    background-color: #fff;
    letter-spacing: 0;
    color: #000;
    border-radius: 0;
    width: 100%;
    padding: 12px 32px;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    transition: all .7s ease-in-out;
    text-decoration: none;
    cursor: pointer;
    border:0;
    display: inline-block;
    border-radius: 30px;
}
.w-form-done{
    padding: 20px;
    text-align: center;
    background-color: #dddddd;
    color: black;

}
.spinner{
border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #000;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
}

.affiliate-image{
    width:940px;
    height:347px;
}



@media only screen and (max-width: 576px) {
    .affilates{
   margin-top: 115px;
    }
    h3{
        align-items: center;
    }
    .grid-layout{
        // grid-column-gap: 50px;
        grid-template-rows: auto;
        margin-bottom: 50px;
        font-family: Sf Pro, sans-serif;
        grid-template-columns: auto;
        grid-auto-columns: 1fr;
        display: grid;
        grid-row-gap: 16px;
        justify-items: center;
        align-items: center;
        text-align: center;

    }
    .affiliate-image{
        width:390px;
        height:144px;
    }
}


