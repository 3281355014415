.patent-Invovation-wrapper{
    background-color: #151515;
    padding: 5% 5% 0%;
    margin-top: 110px;
    .patent-heading{
        letter-spacing: .5px;
        text-transform: uppercase;
        margin-bottom: 15px;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.8em;
    }
    .patent-sub-heading{
        font-size: 2rem;
    }
    .patent-para-text{
        font-size: 20px;
        font-weight: 400;
        line-height: 40px;
    }
}

@media only screen and (max-width: 475px) {
    .patent-Invovation-wrapper{
        margin-top: 110px;
        .patent-sub-heading{
            font-size: 22px;
            font-weight:bold;
        }
    }
}

.patent-product-wrapper{
    background-color: #151515;
    padding: 10% 2% 2%;

    .product-title{
        font-size:5.5vh;
    }

    .product-description{
        padding-bottom: 60px;
        font-size: 30px;
        font-weight: 400;
        line-height: 30px;
    }

    .product-patent-detail{
        .content-holder{
            min-height: 250px;

            .detail-title{
                font-size: 20px;
                font-weight: 400;
                line-height: 24px;
            }
            .detail-description{
                font-size: 16px;
                line-height: 23px;
                color: #d4d4d4;
                margin-bottom: 50px;
            }
        }
        
    }

    .icon-holder{
        border-radius: 20px;
        width: 60px;
        height: 60px;
        margin-bottom: 30px;

        img{
            width: 20px;
            height: 20px;
        }
    }
}

@media only screen and (max-width: 475px) {
    .patent-product-wrapper{
        .product-title{
            font-size:16px;
            margin: 8px 0px 20px 0px;
        }
        .product-description{
            font-size: 20px;
        }
    }
}

.patent-detail-wrapper{
    background-color: black;
    padding-top: 100px;
    padding-bottom: 100px;
    .detail-section-holder{
        padding-top: 50px;
        padding-bottom: 50px;
        border-bottom: 1px solid gray;
    }
    .detail-section-holder:last-child{
        border-bottom: none;
    }

    .heading{
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
    }
    .description{
        font-size: 16px;
        line-height: 23px;
    }

}
