.stamped-badge-caption {
    color: #F8F7F4 !important;
    font-size: .85rem !important;
}
.stamped-badge-caption::before {
  content: "from ";
}
.stamped-review-options{
    display:none !important;
  }
  .stamped-reviews-filter-label{
    color: #F8F7F4 !important;
  }

  .stamped-header-title
  {
    display: block !important;
    color: #F8F7F4 !important;
  }  

  .stamped-summary-text, .stamped-summary-text-1, .created, .author, .stamped-review-header-title, .stamped-review-content-body{
    color: #F8F7F4 !important;
  }

  .stamped-review-header .stamped-verified-badge{
    color:#34A853 !important;
  }

  .stamped-summary-actions-newreview{
    border-radius: 20px !important;
    color: #F8F7F4 !important;
  }

  .stamped-summary-actions-newquestion:before, .stamped-summary-actions-newreview:before, .stamped-summary-actions-mobile-filter i:before, .stamped-summary-actions-clear:before
{
 color: #F8F7F4 !important;
}

div[data-widget-style*=standard] .summary-rating-count {
    color: #F8F7F4 !important;
}


 .new-review-form, .stamped-content form{
    color:  #F8F7F4;
 }
 .stamped-form-label {
    font-size: 14px;
    line-height: 20px;
    color:  #F8F7F4;
}
.stamped-file-uploader-btn {
    border-radius: 0 !important;
    color: gray;
}

.summary-rating .summary-rating-bar > div {
     background:  #F8F7F4 !important;
  }
  .summary-rating .summary-rating-bar  {
    background: black !important;
 }

 .stamped-summary-actions-mobile-filter{
  color:  #F8F7F4;
 }
.stamped-summary-actions-clear{
  color:  #F8F7F4;
}
.stamped-fa::before {
  color: #FFE33F !important;
}
.stamped-fa-star-half-o::before {
  clip-path: polygon(0 0, 50% 0, 50% 100%, 0 100%);
}
