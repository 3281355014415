$whiteColor: #ffffff;
$blackColor: #000000;
$backgroundColor: #1f1f1f;
$subHeadingColor: #FFFFFF80;
$inputFieldBgColor: #C4C4C433;
$blueColor: #0171DE;
$navBgColor: #DCDFE2;
$camouflageColor: #788475;
$lightPurpleColor: #848EC5;
$redColor: #EA4335;
$greenColor: #34A853;
$sandalColor: #BF9874;
$cardBgColor: #333333;
$borderColor: #707070;
$placeholderColor: #FFFFFF99;
$descriptionColor: #D9D9D9;
$verticalLineColor: #696969;
$tableBorderColor: #464646;
$sfProFont: 'sf-pro', sans-serif;
$defaultPadding: 2rem;
$largerFontSize: 3rem;

:export {
camoColor: $camouflageColor;
}