
@media (min-width: 769px) {
    #desktop-klaviyo-signup {
      display: block;
    }
    #mobile-klaviyo-signup {
      display: none;
    }
  }
  

  @media (max-width: 768px) {
    #mobile-klaviyo-signup {
      display: block;
    }
    #desktop-klaviyo-signup {
      display: none;
    }
  }
  