.cart-section{

  .cart-top{
    background-color: #F6F6F6;

  }

  .cart-heading{
    font-size: 45px;
    font-weight: 700;
    line-height: 48.7px;
    text-align: left;
  }

  .product-name{
    font-size: 24px;
    font-weight: 590;
  }

  .card-img-top{
    cursor: pointer !important;
  }

  .product-price{
    font-size: 20px;
    font-weight: 400;
    line-height: 48.7px;
  }
  .product-type-holder{
    padding: 24px 0px 32px;
    border-top: 1px solid #000 !important; 

    &:first-child {
        border-top: none !important;
    }

    .cart-count-container{
      font-size: 20px;
      padding: 10px 20px ;
      border-radius: 40px;
      border: 1px solid #000;
    }

    .desc-message{
      font-size: 16px;
      font-weight: 400;
      line-height: 48.7px;
      text-align: center;
    }

    .add-to-cart-btn{
      width: 156px !important;
      border-radius: 20px;
      background-color: #0071DE;
      font-size: 16px;
    }

    .add-to-cart-btn:hover{
      background-color: #fff;
      color: #0071DE !important;
      border:1px solid #0071DE;
    }
  }

  .detail-cart{
    background-color: #F6F6F6;
    position: sticky;
    bottom: 0; 
    z-index: 10; 

    .sub-total{
      font-size: 16px;
      font-weight: 510;
      line-height: 20px;
      text-align: left;
    }

    .total-price{
      font-size: 16px;
      font-weight: 510;
      line-height: 20px;
      text-align: left;
    }


    .checkout-btn{
      font-size: 16px;
      font-weight: 590;
      line-height: 20px;
      text-align: center;
      height:55px;
      border-radius: 30px;
      background-color: #0071DE;
  
    }
    
    .checkout-btn:hover{
      background-color: transparent;
      color: #0071DE !important;
      border:1px solid #0071DE;
  
    }

  }

  .gray-bar{
    height: 8px;
    border-radius: 8px;
    background-color: #DCDFE2
  }

  .blue-bar{
    height: 8px;
    border-radius: 8px;
    background-color: #0071DE
  }
  
}
.subtotal-container{
-webkit-transform: translateZ(0);
}
@media only screen and (max-width: 475px) {
  .cart-section{



    .product-name{
      font-size: 20px;
    }

    .product-price{
      font-size: 18px;
    }
    .product-type-holder .cart-count-container{
      padding: 8px 15px;
    }
  }
}




// :root {
//   --star-size: 30px;
//   --star-color: #fff;
//   --star-background: #fc0;
// }

// .cart-section {
//   background-color: $whiteColor;
//   padding: 5rem;

//   .blue-button {
//     font-size: 1.25rem;
//     font-weight: 500;
//     letter-spacing: 3px;
//     background-color: $blueColor;
//     color: $whiteColor;
//     border-radius: 25px;
//     padding: 0.5rem 1.5rem;
//     border: none;
//   }

//   .cart {
//     width: 60%;

//     h1 {
//       font-size: 3rem;
//       font-weight: 600;
//       text-transform: uppercase;
//     }

//     p {
//       color: $borderColor;
//       font-size: 1rem;
//     }

//     h2 {
//       font-size: 1.5rem;
//       font-weight: 700;
//       margin: 4rem 0 0.5rem;
//     }

//     .cart-item {
//       background-color: #F5F5F5;
//       border-radius: 10px;
//       margin: 2rem 0;
//       padding: 2rem 0;
//       position: relative;

//       .product-image {
//         width: 30%;
//       }

//       .product-info {
//         width: 25%;

//         h3 {
//           font-size: 1.5rem;
//           text-transform: capitalize;
//           letter-spacing: 1px;
//           font-weight: 600;
//         }

//         p {
//           font-size: 1.5rem;
//           font-weight: 300;
//         }
//       }

//       .product-add-info {
//         width: 30%;

//         ul {
//           padding-left: 0;

//           li {
//             width: 15px;
//             height: 15px;
//             border-radius: 50%;
//             list-style: none;
//             margin: 0 4px;
//           }
//         }

//         .qty-select {
//           select {
//             background-color: $whiteColor;
//             color: $backgroundColor;
//             padding: 0.25rem 1.5rem;
//             border-radius: 25px;
//             font-size: 1.5rem;
//           }

//         }
//       }

//       .close-icn {
//         position: absolute;
//         top: 20px;
//         right: 30px;
//       }
//     }
//   }

//   .price {
//     width: 40%;
//     padding: 3rem;
//     margin-top: 5rem;

//     .price-box {
//       border: 1px solid #000000;
//       border-radius: 10px;
//       color: $borderColor;
//       font-size: 1.25rem;

//       p.title {
//         border-bottom: 1px solid #000000;
//         padding: 0.5rem 2rem;
//       }

//       .info {
//         padding: 0 2rem;
//       }

//       .imp {
//         font-weight: 700;
//         color: $backgroundColor;
//       }

//       button {
//         border-radius: 25px;
//         border: none;

//         img {
//           width: auto;
//         }

//         &.govx-id {
//           padding: 0.25rem 0.5rem;
//         }

//         &.black {
//           background-color: $backgroundColor;
//         }

//         &.purple {
//           background-color: #5830EE;
//         }

//         &.grey {
//           background-color: $navBgColor;
//         }
//       }

//       .govx-id-info {
//         font-size: 0.85rem;
//         letter-spacing: 1px;
//         padding-right: 0.5rem;
//         margin-top: 5px;
//         text-decoration: none;
//       }

//       .note {
//         margin-top: 5rem;
//         font-size: 0.95rem;
//       }

//       .blue-button {
//         letter-spacing: 1px;
//         font-size: 1rem;
//         letter-spacing: 2px;
//         width: 100%;
//         padding: 0.75rem;
//       }

//       .social {
//         margin-right: 5px;
//         width: 33%;

//         button {
//           width: 100%;
//           padding: 0.25rem 0;
//         }
//       }

//       .link {
//         text-decoration: underline;
//       }

//       .footer {
//         margin-top: 5rem;
//         margin-bottom: 2rem;
//         color: $backgroundColor;
//         font-size: 1rem;
//       }
//     }
//   }

// }

// @media only screen and (min-width: 768px) and (max-width: 1200px) {
//   .cart-section {
//     padding: 3rem 2rem;

//     .cart {
//       width: 60%;
//       padding: 0 1.5rem;

//       h2 {
//         font-size: 1rem !important;
//         margin: 2rem 0 0;
//       }

//       .cart-item {
//         padding: 1rem 0;

//         .product-img {
//           width: 20%;

//           img {
//             width: 100%;
//           }
//         }

//         .product-info {
//           width: 30%;

//           h3 {
//             font-size: 1rem;
//           }

//           p {
//             font-size: 1rem;
//           }
//         }

//         .product-add-info {
//           width: 35%;

//           ul {

//             li {
//               width: 12px;
//               height: 12px;
//             }
//           }

//           .qty-select {
//             select {
//               padding: 0.25rem 1.25rem;
//               font-size: 1.25rem;
//             }

//           }
//         }

//         .close-icn {
//           top: 10px;
//           right: 20px;

//           img {
//             width: 13px;
//           }
//         }
//       }
//     }

//     .price {
//       width: 40%;
//       padding: 1rem;

//       .price-box {
//         font-size: 1rem;

//         p.title {
//           padding: 0.5rem 1rem;
//           font-weight: 600;
//         }

//         .info {
//           padding: 0 1rem;
//         }

//         .note {
//           margin-top: 3rem;
//         }

//         button {
//           &.govx-id {
//             padding: 0 0.5rem;
//           }

//           img {
//             height: 15px;
//           }
//         }

//         .social {
//           button {
//             padding: 0;

//             img {
//               height: 10px;
//             }
//           }
//         }

//         .footer {
//           margin-top: 2rem;
//           margin-bottom: 1rem;
//         }
//       }
//     }

//   }
// }

// @media only screen and (max-width: 767px) {
//   .cart-section {
//     padding: 2rem;
  
//     .blue-button {
//       font-size: 1rem;
//       letter-spacing: 3px;
//       padding: 0.5rem 1.5rem;
//     }
  
//     .cart {
//       width: 100%;
  
//       h1 {
//         font-size: 1.5rem;
//       }
  
//       p {
//         font-size: 0.9rem;
//       }
  
//       h2 {
//         font-size: 1rem;
//         margin: 1rem 0;
//       }
  
//       .cart-item {
//         padding: 1rem 0;
//         position: relative;
  
//         .product-img {
//           width: 20%;
//           img {
//             width: 100%;
//           }
//         }
  
//         .product-info {
//           width: 25%;
  
//           h3 {
//             font-size: 1rem;
//             text-transform: capitalize;
//             letter-spacing: 1px;
//             font-weight: 600;
//           }
  
//           p {
//             font-size: 1rem;
//             font-weight: 300;
//             margin: 0;
//           }
//         }
  
//         .product-add-info {
//           width: 30%;
  
//           ul {
//             padding-left: 0;
//             margin-bottom: 10px;
  
//             li {
//               width: 12px;
//               height: 12px;
//               margin: 0 2px;
//             }
//           }
  
//           .qty-select {
//             select {
//               padding: 0.25rem 1rem;
//               font-size: 0.75rem;
//             }
  
//           }
//         }
  
//         .close-icn {
//           position: absolute;
//           top: 5px;
//           right: 10px;
//           img {
//             width: 12px;
//           }
//         }
//       }
//     }
  
//     .price-box {
//       color: $borderColor;
//       font-size: 1.25rem;

//       .info {
//         padding: 0 0.5rem;
//       }

//       .imp {
//         font-weight: 700;
//         color: $backgroundColor;
//       }

//       .blue-button {
//         letter-spacing: 1px;
//         font-size: 1rem;
//         letter-spacing: 2px;
//         width: 100%;
//         padding: 0.75rem;
//         margin-top: 1rem;
//       }

//       .footer {
//         margin-top: 1rem;
//         margin-bottom: 1rem;
//         color: $backgroundColor;
//         font-size: 1rem;
//         text-decoration: underline;
//       }
//     }
  
//   }
// }