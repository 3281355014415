.banner-wrap {
  height: 70vh;
  margin-top: 145px;
}

// .home-banner-wrap {
//   background: url("../../public/assets/images/v2/model-releasing-psoas-iliacus-with-mark.jpg")
//     no-repeat 50% 43% / cover rgba(21, 21, 21, 0.93);
//   background-attachment: fixed;
//   background-clip: border-box;
//   height: 100%;
// }

.btn-actions {
  margin: 20px 0;
}
.hpw-img {
  overflow: hidden; /* Hide overflow to create the zoom effect */
}

.hpw-img img {
  transition: transform 1s ease; /* Smooth transition for zoom */
  transform: scale(1.1); /* Initial scale */
}

.hpw-img.zoom-in img {
  transform: scale(1); /* Scale the image when it comes into view */
}

.hpw-content {
  opacity: 0; /* Start hidden */
  transform: translateY(20px); /* Start slightly lower */
  transition: opacity 1s ease, transform 1s ease; /* Increased duration for smoother transition */
}

.hpw-content.fade-in {
  opacity: 1; 
  transform: translateY(0);
}
.fade-in-left {
  opacity: 0;
  transform: translateX(100%);
  animation: fadeInLeft 1s forwards;
}

@keyframes fadeInLeft {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.zoom-out {
  animation: zoomOut 1s forwards;
}

@keyframes zoomOut {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rotate-y {
  0% {
    transform:  rotateY(30deg);
  }

  100% {
    transform: rotateY(0deg);
  }
}

.rotate-animation {
  animation: rotate-y 0.5s ease-in-out forwards; /* Adjust duration as needed */
}
// .btn {
//   position: relative; /* Position relative for pseudo-elements */
//   overflow: hidden; /* Hide overflow for animations */
// }

// .btn-outline {
//   opacity: 0; /* Start hidden for Learn More button */
//   transform: translateX(-20px); /* Start slightly off-screen */
//   transition: opacity 0.5s ease, transform 0.5s ease; /* Smooth transition */
// }

// .btn-outline.fade-in {
//   opacity: 1; 
//   transform: translateX(0);
// }

// .btn-blue {
//   animation: zoom-in-out 1s infinite; /* Zoom in and out effect */
// }

// @keyframes zoom-in-out {
//   0%, 100% {
//     transform: scale(1);
//   }
//   50% {
//     transform: scale(1.1); /* Scale up */
//   }
// }


@keyframes zoom-in {
  0% {
    transform: scale(0.8); /* Start smaller */
  }
  100% {
    transform: scale(1); /* End at actual size */
  }
}
.btn-blue {
  background: #0071de !important;
  min-width: 110px;
  font-size: 20px !important;
  font-weight: 500 !important;
  color: $whiteColor !important;
  padding: 9px 30px !important;
  border-radius: 20px !important;
  transition: background 1s;
  &:hover {
    background: #d9d9d9 !important;
    color: #000000 !important;
  }
}

.btn-outline {
  background: transparent !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  color: $whiteColor !important;
  padding: 9px 30px !important;
  border-radius: 20px !important;
  transition: background 1s;
  border: 1px $whiteColor solid !important;
  &:hover {
    background: #d9d9d9 !important;
    color: #000000 !important;
  }
}

// .home-prod-wrap {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   grid-template-rows: 1fr;
//   grid-gap: 20px;
//   justify-content: space-between;
//   align-items: stretch;
//   overflow: hidden;

// }

.left-img-content {
  grid-template-columns: 1fr 1fr;
}

.right-img-content {
  grid-template-columns: 1fr 3fr
}

.bpi-text {
  line-height: 26px;
  color: $whiteColor;
  margin: 20px 0px;
  font-family: Roboto, sans-serif;
  font-size: 27px;
}


.hpw-img {

  max-height: 900px;
    
  img {
    width: 100%;
    max-height: 950px;
    vertical-align: middle;
    object-fit: cover;
  }
}

.hpw-content {
  padding-top: 20px;
  padding-bottom: 20px;
  align-self: center;

  .prod-title {
    font-size: 3.4em;
    font-weight: 700;
    color: $whiteColor;
  }

  .prod-sub-title {
    font-size: 2em;
    font-weight: 400;
    color: $whiteColor;
  }

  .prod-tag-line {
    font-size: 0.9em;
    font-weight: 300;
    color: silver;
  }

  .prod-desc {
    font-size: 16px;
    font-weight: 300;
    color: $whiteColor;
    line-height: 26px;
    margin-bottom: 0;
  }
}

.newsletter-wrap {
  padding: 80px 0;

  .nlw-title {
    font-size: 40px;
    font-weight: 500;
    color: $whiteColor;
    margin-bottom: 20px;
  }

  .newsletter-form p {
    font-size: 20px;
    font-weight: 400;
    color: $whiteColor;
  }

  .newsletter-form input {
    font-size: 18px;
    font-weight: 400;
    border: 1px solid rgb(84, 70, 47);
    border-radius: 8px;
    padding: 12px 15px;
  }

  .newsletter-form .btn-blue {
    font-size: 18px !important;
    font-weight: 400 !important;
    padding: 12px 50px !important;
    border-radius: 30px !important;
  }
}


@media only screen and (max-width: 576px) {
  .banner-wrap {
    height: 70vh;
    margin-top: 120px;
  }
  .home-prod-wrap{
    padding: 0px 20px !important;
  }
  .btn-blue {
    font-size: 16px !important;
    min-width: 80px;
  }
  .btn-outline {
    font-size: 16px !important;
    min-width: 80px;
  }
  .bpi-text{
    font-size: 18px;
    margin: 5px 0px;
  }

  .hpw-img {
    max-height: 900px;
    
    img {
      width: 100%;
      height: 412px;
    }
  }
  

  .hpw-content {
    .prod-title {
      font-size: 30px;
    }
  
    .prod-sub-title {
      font-size: 1.4em;
      font-weight: 400;
      color: $whiteColor;
    }
  
    .prod-tag-line {
      font-size: 0.9em;
      font-weight: 300;
      color: silver;
    }
  
    .prod-desc {
      font-size: 16px;
      font-weight: 300;
      color: $whiteColor;
      line-height: 26px;
      margin-bottom: 0;
    }
  }
  .newsletter-wrap {
    padding: 0 18px;
    .nlw-title{
      font-size: 26px;
    }
    .newsletter-form p{
      font-size: 16px;
    }
    .newsletter-form .btn-blue {
      padding: 12px 30px !important;
    }
  }
}