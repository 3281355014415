.header {
  background: #0c1317;
  border-bottom: 1px #848484 solid;
  // position: relative;
  position: fixed; /* Make the header fixed */
  // top: 20px; /* Position it at the top of the viewport */
  left: 0; /* Ensure it starts from the left */
  width: 100%; /* Make it span the full width */
  z-index: 1000; /* Ensure it stays above other elements */

  .navbar {
    padding: 0;
    position: unset;
  }

  .navbar-brand {
    transform: translateX(-70px);
  }

  .menu-icon-section:focus {
    box-shadow: none;
  }

  .even-measure {
    min-width: 100px;
    text-align: left;
  }

  .main-menu {
    li {
      .nav-link {
        font-size: 16px;
        font-weight: 500;
        color: $whiteColor;
        padding: 60px 20px !important;
      }
    }
  }

  .drop-menu-wrap {
    background: #0c1317;
    position: absolute;
    top: 101%;
    left: 0;
    right: 0;
  }

  .shop-dropdown-menu {
    display: none;
    padding: 35px 0;
    z-index: 5;
  }

  &:has(.shop-link:hover) {
    .shop-dropdown-menu {
      display: block;
      z-index: 5;
    }
  }

  .menu-prod-list {
    padding-left: 0;
    list-style-type: none;
    a {
      color: $whiteColor;
      text-decoration: none;
      font-weight: 500;
    }
    img {
      margin-bottom: 20px;
    }

    .mpl-name {
      font-size: 15px;
    }

    p {
      font-size: 10px;
      margin-bottom: 0;
    }
  }

  .learn-dropdown-menu {
    display: none;
    z-index: 5;
  }

  &:has(.learn-link:hover) {
    .learn-dropdown-menu {
      display: block;
      z-index: 5;
    }
  }

  .learn-menu {
    padding: 50px 0;
    list-style-type: none;
    a {
      font-size: 15px;
      font-weight: 500;
      color: $whiteColor;
      display: block;
      padding: 5px 0;
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 475px) {
  .header {
    background: #0c1317;
    border-bottom: none;
    // position: relative;
    position: fixed; /* Make the header fixed */
    top: 0px; /* Position it at the top of the viewport */
    left: 0; /* Ensure it starts from the left */
    width: 100%; /* Make it span the full width */
    z-index: 1000; /* Ensure it stays above other elements */
    padding-top:10px;

    .navbar {
      padding: 0;
      position: unset;
    }

    .menu-icon-section:focus {
      box-shadow: none;
    }

    .custom-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }

    .navbar-brand {
      transform: translateX(0px);
      padding-top: 15px;
    }
    .even-measure {
      min-width: 100px;
      text-align: left;
    }

    .main-menu {
      li {
        .nav-link {
          font-size: 16px;
          font-weight: 500;
          color: $whiteColor;
          padding: 60px 20px !important;
        }
      }
    }

    .drop-menu-wrap {
      background: #0c1317;
      position: absolute;
      top: 101%;
      left: 0;
      right: 0;
      z-index: 2000;
    }

    .shop-dropdown-menu {
      display: none;
      padding: 35px 0;
      z-index: 5;
    }

    &:has(.shop-link:hover) {
      .shop-dropdown-menu {
        display: block;
        z-index: 5;
      }
    }

    .menu-prod-list {
      padding-left: 0;
      list-style-type: none;
      a {
        color: $whiteColor;
        text-decoration: none;
        font-weight: 500;
      }
      img {
        margin-bottom: 20px;
      }

      .mpl-name {
        font-size: 15px;
      }

      p {
        font-size: 10px;
        margin-bottom: 0;
      }
    }

    .learn-dropdown-menu {
      display: none;
      z-index: 5;
    }

    &:has(.learn-link:hover) {
      .learn-dropdown-menu {
        display: block;
        z-index: 5;
      }
    }

    .learn-menu {
      padding: 50px 0;
      list-style-type: none;
      a {
        font-size: 15px;
        font-weight: 500;
        color: $whiteColor;
        display: block;
        padding: 5px 0;
        text-decoration: none;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .header-nav-div {
    position: relative;
    background-color: $whiteColor;

    .header-nav {
      min-height: 80px;

      li {
        margin-right: 1rem;

        a {
          color: $backgroundColor;
        }
      }
    }

    .logo-div {
      left: 0;

      img {
        height: auto;
        width: 80px;
      }
    }
  }

  .grey-nav {
    background-color: $navBgColor;
  }

  .hamburger-menu {
    .header-nav-mob {
      position: absolute;
      z-index: 100;
      width: 100%;
      //   .accordion .accordion-button:after {
      //     font-family: 'FontAwesome';
      // content: "\f068";
      // float: right;
      //   }

      //   .accordion .accordion-button.collapsed:after {
      //     /* symbol for "collapsed" panels */
      //     content: "\f067";
      // }

      .accordion-button::after {
        background-image: url("/assets/icons/accordion-plus-icon.svg");
      }

      .accordion-button:not(.collapsed)::after {
        background-image: url("/assets/icons/accordion-minus-icon.svg");
      }

      .accordion-header a {
        text-decoration: none;
        color: $backgroundColor;
        font-weight: 500;
        font-size: 18px;
      }

      .accordion-body {
        ul {
          li {
            list-style-type: none;

            a {
              color: $backgroundColor;
            }
          }
        }

        .shop-products-list {
          padding-left: 0;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 20px;
          row-gap: 20px;

          li {
            background-color: $navBgColor;
            border-radius: 15px;
            text-align: center;

            img {
              width: 120px;
              height: auto;
              min-height: 120px;
              object-fit: contain;
            }

            h5 {
              font-size: 14px;
            }

            p {
              font-size: 10px;
            }
          }
        }

        .link-list {
          li {
            margin-bottom: 1rem;

            a {
              font-size: 16px;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

.mobile-lists {
  background: #0C1218;

  border-bottom: 1px solid #848484;
  margin-top: 160px;
  position: fixed;
  top: 0; /* Position it at the top of the viewport */
  left: 0; /* Ensure it starts from the left */
  width: 100%; /* Make it span the full width */
  z-index: 1000;

  .menu-list {
    font-size: 24px;
    font-weight: 510;
    line-height: 20px;
  }
  // .mobile-product-nav {
  //   max-height: 500px;
  //   overflow-y: scroll;
  // }
  // .mobile-header{
  //   max-height: 600px;
  //   overflow-y: scroll;
  // }
  //   .mobile-body {

  //     overflow-y: scroll; /* Enable vertical scrolling if content exceeds max height */
  //     transition: scroll-top 0.5s ease-in-out;
  //   }

  .mobile-product-nav {
    max-height: 80vh; 
     overflow-y: auto; 
  
  }
  

  .product-name {
    font-size: 15px;
    font-weight: 500;
    line-height: 10.67px;
  }
  .product-description {
    font-size: 10px;
    font-weight: 500;
    line-height: 10.67px;
  }

  .learn-list {
    font-size: 16px;
    font-weight: 500;
    line-height: 10.67px;
  }

  .accordion-header {
    border-color: #848484 !important;
  }

  .accordion-item {
    background-color: black;

    .accordion-button {
      background-color: black !important;
      box-shadow: none !important;
    }

    .accordion-button:focus {
      box-shadow: none;
      border: 0px !important;
    }

    .accordion-button::after {
      content: "";
      background-image: url("/assets/images/new-image/plus-icon.png") !important;
    }

    .accordion-button:not(.collapsed)::after {
      background-image: url("/assets/images/new-image/minus-icon.png") !important;
    }

    .no-icn:after {
      content: "";
      background-image: none !important;
    }
    .no-icn:not(.collapsed)::after {
      background-image: none !important;
    }
  }
}

.common-section-ad {
  position: fixed;
  background-color: #0c1317;
  bottom: 0px;
  width: 100%;
  z-index: 10 !important;
  // border-top: 1px solid #848484;
  box-shadow: 0px -5px 5px rgb(132, 132, 132, 0.6);

  .price-section {
    border-color: #848484 !important;
    height: 70px;
  }

  .product-name {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }

  .product-price {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  button {
    background-color: #0071de !important;
    padding: 10px 5px 10px 4px;
    border-radius: 20px;
    width: 118px;
    font-size: 16px;
  }
}

.common-section-top {
  position: fixed; /* Changed to sticky */
  background-color: #0c1317;
  width: 100%;
  z-index: 500 !important;
  border: 1px solid #848484;
  border-left: 0;
  border-right: 0;
  margin-top: 140px;
  .price-section {
    border-color: #848484 !important;
    height: 70px;
  }
  .product-name {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }
  .product-price {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
  button {
    background-color: #0071de !important;
    padding: 10px 5px 10px 4px;
    border-radius: 20px;
    width: 118px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 576px) {
  .common-section-ad {
    position: fixed;
    background-color: #0c1317;
    bottom: 0px;
    width: 100%;
    z-index: 10 !important;

    .price-section {
      border-color: #848484 !important;
      height: 70px;
    }

    .product-name {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
    }

    .product-price {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }

    button {
      background-color: #0071de !important;
      padding: 10px 5px 10px 4px;
      border-radius: 20px;
      width: 100%;
      font-size: 16px;
    }
  }
}

.search-wrapper {
  background-color: rgba(12, 18, 24, 1);

  input {
    border: 1px solid #848484;
    border-radius: 20px;
    padding: 10px 15px;
    font-size: 16px;
  }

  .popular {
    padding: 24px 12px 0px;
    font-size: 16px;
    font-weight: 500;
    line-height: 10.67px;
  }

  .tags {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 8px 16px;
    margin-right: 5px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 590;
    line-height: 16px;
    color: white;
  }
}
@media (min-width: 475px) and (max-width: 991px) {
  .header .navbar-brand {
    transform:none;
  }
  .header .container {
  margin-top: 25px;
}
}

// .top-label {
//   background-color: $blueColor;
//   padding: 10px 20px;

//   * {
//     color: $whiteColor;
//   }

//   span {
//     cursor: pointer;
//   }
// }

// .dark-nav {
//   background-color: transparent;
// }

// .grey-nav {
//   background-color: $navBgColor;

//   .header-nav {
//     a {
//       color: $backgroundColor !important;
//     }
//   }
// }

// .light-nav {
//   background-color: $whiteColor;

//   .header-nav {
//     a {
//       color: $backgroundColor !important;
//     }
//   }
// }

// .header-nav-div {
//   width: 100%;
//   // min-height: 150px;
//   position: absolute;
//   z-index: 10;

//   .header-nav {
//     align-items: center;
//     padding: 0;
//     margin: 0;

//     li {
//       list-style-type: none;
//       font-size: 15px;
//       padding: 0 2rem;
//       height: 120px;

//       .sub-nav {
//         visibility: hidden;
//         opacity: 0;
//         transition: opacity 1.5s, visibility 1.5s;
//       }

//       &:hover {
//         .sub-nav {
//           visibility: visible;
//           opacity: 1;
//         }
//       }

//       a {
//         text-decoration: none;
//         color: $whiteColor;
//         font-weight: 500;

//         :active {
//           color: $blueColor;
//         }
//       }
//     }
//   }

//   .logo-div {
//     position: relative;
//     left: -150px;
//     cursor: pointer;

//     img {
//       height: 120px;
//       width: 180px;
//       object-fit: cover;
//     }
//   }

//   .nav-dropdown {
//     background-color: $navBgColor;
//     padding: 2rem;
//     position: absolute;
//     top: 100%;
//     border: 1px solid $borderColor;

//     ul {
//       padding: 0;
//     }

//     li {
//       margin-bottom: 1rem;
//       height: 30px;

//       a {
//         color: $backgroundColor;
//       }
//     }
//   }

//   .shop-extended-section {
//     background-color: $navBgColor;
//     position: absolute;
//     left: 0;
//     width: 100%;
//     padding: 5rem;
//     top: 100%;
//     border: 1px solid $borderColor;

//     .shop-products-list {
//       display: flex;
//       justify-content: space-evenly;
//       align-items: center;
//       padding-bottom: 5rem;

//       li {
//         cursor: pointer;

//         img {
//           height: 130px;
//           width: auto;
//         }

//         div {
//           text-align: center;
//         }
//       }
//     }

//     h6 {
//       text-align: center;
//       font-weight: 600;

//       a {
//         text-decoration: underline;
//       }
//     }
//   }
// }

// .header-nav-div:hover {
//   background-color: $navBgColor;

//   .header-nav li a {
//     color: $backgroundColor;
//   }
// }

// @media only screen and (min-width: 768px) and (max-width: 992px) {
//   .header-nav-div {
//     .header-nav li {
//       margin-right: 2rem;
//     }

//     .logo-div {
//       left: -40px;

//       img {
//         height: 115px;
//         width: 140px;
//       }
//     }
//   }
// }

// @media only screen and (min-width: 451px) and (max-width: 767px) {
//   .header-nav-div {
//     background-color: $navBgColor;
//     height: 90px;

//     .header-nav {
//       li {
//         margin-right: 3rem;

//         a {
//           color: $backgroundColor;
//         }
//       }
//     }

//     .logo-div {
//       left: 0;

//       img {
//         height: auto;
//         width: 80px;
//       }
//     }

//     .shop-extended-section {
//       padding: 2rem;

//       .shop-products-list {
//         padding-left: 0;
//         padding-bottom: 3rem;

//         li {
//           margin-right: 1rem;

//           img {
//             height: auto;
//             max-height: 80px;
//             width: 120px;
//           }

//           div {
//             margin-top: 1.5rem;
//           }
//         }
//       }
//     }
//   }
// }

// @media only screen and (max-width: 450px) {
//   .header-nav-div {
//     position: relative;
//     background-color: $whiteColor;

//     .header-nav {
//       min-height: 80px;

//       li {
//         margin-right: 1rem;

//         a {
//           color: $backgroundColor;
//         }
//       }
//     }

//     .logo-div {
//       left: 0;

//       img {
//         height: auto;
//         width: 80px;
//       }
//     }
//   }

//   .grey-nav {
//     background-color: $navBgColor;
//   }

//   .hamburger-menu {
//     .header-nav-mob {
//       position: absolute;
//       z-index: 100;
//       width: 100%;
//       //   .accordion .accordion-button:after {
//       //     font-family: 'FontAwesome';
//       // content: "\f068";
//       // float: right;
//       //   }

//       //   .accordion .accordion-button.collapsed:after {
//       //     /* symbol for "collapsed" panels */
//       //     content: "\f067";
//       // }

//       .accordion-button::after {
//         background-image: url('/assets/icons/accordion-plus-icon.svg');
//       }

//       .accordion-button:not(.collapsed)::after {
//         background-image: url('/assets/icons/accordion-minus-icon.svg');
//       }

//       .accordion-header a {
//         text-decoration: none;
//         color: $backgroundColor;
//         font-weight: 500;
//         font-size: 18px;
//       }

//       .accordion-body {
//         ul {
//           li {
//             list-style-type: none;

//             a {
//               color: $backgroundColor;
//             }
//           }
//         }

//         .shop-products-list {
//           padding-left: 0;
//           display: grid;
//           grid-template-columns: repeat(2, 1fr);
//           column-gap: 20px;
//           row-gap: 20px;

//           li {
//             background-color: $navBgColor;
//             border-radius: 15px;
//             text-align: center;

//             img {
//               width: 120px;
//               height: auto;
//               min-height: 120px;
//               object-fit: contain;
//             }

//             h5 {
//               font-size: 14px;
//             }

//             p {
//               font-size: 10px;
//             }
//           }
//         }

//         .link-list {
//           li {
//             margin-bottom: 1rem;

//             a {
//               font-size: 16px;
//               text-decoration: none;
//             }
//           }
//         }
//       }
//     }
//   }
// }
