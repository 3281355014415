.about-wrapper {
    // margin-top: 110px;
    position: relative;
    // section one
    .top-details-section {
        .about-top-section {
            background-color: #f33434;
            padding: 10% 5% 7%;


            .title {
                font-size: 30px;
                font-weight: bold;
                color: white;
            }

            .description {
                font-size: 30px;
                font-weight: 400;
                line-height: 32px;
                color: white;
            }
        }
    }

    @media only screen and (max-width: 475px) {
        .top-details-section {
            .about-top-section {
                background-color: transparent;
                padding: 5% 5%;

                .title {
                    font-size: 33px;
                    margin-bottom: 50px;
                }

                .description {
                    font-size: 21px;
                    line-height: 23px;
                }
            }
        }
    }

    // section two
    .user-detail-section {
        background-color: #252525;
        padding: 80px 0px;


        p {
            font-size: 30px;
            font-weight: 400;
            color: white;
            padding: 40px;
        }
    }

    @media only screen and (max-width: 475px) {

        .user-detail-section {
            background-color: #d52222;
            padding: 80px 20px;


            p {
                font-size: 21px;
                line-height: 23px;
                padding: 0%;
            }
        }
    }

    // section three
    .principles-section {
        padding: 150px 5% 100px;

        .heading-section {
            font-size: 44px;
            line-height: 48px;
            margin-bottom: 50px;
        }

        .card-section {
            .title {
                margin-bottom: 16px;
                font-size: 28px;
                font-weight: 500;
                line-height: 28px;
            }

            .description {
                font-size: 20px;
                font-style: italic;

            }
        }
    }

    @media only screen and (max-width: 475px) {
        .principles-section {
            padding: 42px 20px;

            .heading-section {
                font-size: 24px;
                line-height: 32px;
            }

            .card-section {
                .title {
                    font-size: 20px;
                }

                .description {
                    font-size: 15px;
                }
            }
        }
    }

    // section four
    .about-founder {
        background-color: #f6f6f6;
        padding: 5%;

        .founder-title {
            font-size: 32px;
            line-height: 32px;
            margin-bottom: 24px;
        }

        .founder-details {
            padding-left: 0;
            font-size: 22px;
            line-height: 26px;
        }
    }

    @media only screen and (max-width: 475px) {
        .about-founder {
            background-color: #f6f6f6;
            padding: 7% 5% 10%;

            .founder-title {
                font-size: 24px;
                line-height: 30px;
                margin-bottom: 40px;
            }

            .founder-details {
                font-size: 16px;
                line-height: 21px;
            }

            .founder-image {
                width: 320px !important;
                height: 568px;
            }
        }
    }

    // section five
    .meet-behind-section {
        background-color: #eaeaea;
        padding: 80px 30px;

        .meet-container {
            width: 100%;
            max-width: 940px;
            margin-left: auto;
            margin-right: auto;

            .founders-container {
                margin-top: 50px;

                .team-member-image {
                    object-fit: cover;
                    border: 4px solid #fff;
                    border-radius: 4%;
                    margin-bottom: 24px;
                    box-shadow: 4px 6px 5px #0003;
                }

                .founder-name {
                    color: #000;
                    margin-bottom: 6px;
                    font-size: 25px;
                    font-weight: 500;
                    line-height: 32px;
                }

                .founder-position {
                    color: #000;
                    padding-right: 0;
                    font-size: 20px;
                    line-height: 20px;

                }
            }

            .founder-description {
                color: #222;
                text-align: center;
                padding-right: 0;
                font-size: 30px;
                line-height: 27px;
            }
        }
    }

    @media only screen and (max-width: 475px) {
        .meet-behind-section {
            background-color: #eaeaea;
            padding: 80px 30px;

            .meet-container {
                width: 100%;
                max-width: 940px;
                margin-left: auto;
                margin-right: auto;

                .founders-container {
                    margin-top: 50px;

                    .team-member-image {
                        width: 190px !important;
                        height: auto !important;
                        margin-bottom: 5px;
                    }

                    .founder-name {
                        font-size: 16px;
                    }

                    .founder-position {
                        color: #222;
                        font-size: 14px;
                    }
                }

                .founder-description {
                    font-size: 17px;
                    line-height: 21px;
                }
            }
        }
    }

    // section six
    .sections-holder {
        background-color: #fff;
        padding: 10% 6% 5%;

        .section-title {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 16px;
        }

        .section-description {
            font-size: 16px;
            line-height: 22px;
            font-weight: 500;
        }
    }

    // section seven
    .about-card-section {
        background-color: #420073;
        padding: 150px 5% 100px;

        .detail-holder {
            margin-bottom: 125px;

            .title {
                font-size: 30px;
                font-weight: bold;
                color: white;
            }

            .description {
                font-size: 30px;
                font-weight: 400;
                line-height: 32px;
                color: white;
            }
        }

        .card-details {

            .card-title {
                margin-bottom: 16px;
                font-size: 29px;
                font-weight: 500;
                line-height: 28px;

            }

            .card-description {
                font-size: 23px;
                line-height: 32px;
            }
        }
    }

    // section eight
    @media only screen and (max-width: 475px) {

        .about-card-section {
            background-color: #420073;
            padding: 100px 5%;

            .detail-holder {
                margin-bottom: 70px;

                .title {
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: 500;
                }

                .description {
                    font-size: 16px;
                    line-height: 23px;
                }
            }

            .card-details {

                .card-title {
                    font-size: 20px;
                }

                .card-description {
                    font-size: 16px;
                    line-height: 23px;
                }
            }
        }
    }
}
@media only screen and (max-width: 576px) {
.about-wrapper{
    // margin-top: 110px;
    position: relative;
}
}