.footer {
  font-family: 'Roboto';
  background: #0C1317;
  padding-top: 30px;
  margin-top: 100px;
  

  .footer-main {
    padding: 60px 0 30px;

    .fm-col {
      .footer-col-title {
        font-size: 14px;
        font-weight: 700;
        color: #F8F7F4;
        text-transform: uppercase;
        margin-bottom: 24px;
      }

      .footer-menu {
        padding: 0;
        list-style: none;

        a {
          font-size: 14px;
          font-weight: 400;
          color: #F8F7F4;
          text-decoration: none;
          
        }
      }

      .social-list {
        padding: 0px;
        list-style: none;
        gap: 15px;
      }

      .footer-list {
        color: #000; // Default text color
        text-decoration: none; // Remove underline
        transition: color 0.3s ease; // Smooth color transition
      
        &:hover {
          color:#3d3d3d; // Color on hover (example: blue)
          text-decoration: none; // Optional: underline on hover
        }
      
        &:active,
        &:focus {
          color:#3d3d3d; // Color on active or focus state (example: orange)
          text-decoration: none; // Optional: underline for active state
        }
      }
      ul{
        li{
          margin-bottom: 18px;
        }
      }
    }
  }

  .footer-btm {
    border-top: 1px #E4EBF32E solid;
    padding: 15px 0;

    p {
        font-size: 14px;
        font-weight: 400;
        color: #F8F7F4;
        margin-bottom: 0;
    }
  }
}