.manual-section-wrapper {
  // background-color: #0C1317;
  background-color: black;
  padding-top: 25px;
  margin-top: 120px;
  .terms {
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 9px;
  }
  .card {
    opacity: 1;
    border: 1px solid #1d1d1d;
    width: auto;
    // background-color: #0C1317;
    .card-body {
      .image-wrapper {
        img {
          width: 180px !important;
          height: 180px !important;
        }
      }
    }
    .manual-button {
      background-image: none;
      justify-content: center;
      align-items: center;
      width: 110px;
      height: 50px;
      margin-bottom: 10px;
      padding-top: 0;
      padding-bottom: 0;
      font-size: 20px;
      font-weight: 400;
      line-height: 45px;
      display: flex;
      background-color: #0071de;
      background-image: none;
      border-radius: 20px;
    }
  }
}
@media only screen and (max-width: 576px) {
  .manual-section-wrapper {
    margin-top: 120px;
  }
}
