// advertisement section

.top-label {
 background: #0071DE;
  // padding: 10px 20px;
  font-size: 14px;
  font-weight: 510;
  line-height: 20.16px;
  text-align: center;
  position: fixed!important;
  top:0;
  left:0;
  width: 100%;
  z-index: 2000 !important;
  margin-bottom: 25px;


  * {
    color: $whiteColor;
  }

  span {
    cursor: pointer;
  }
}


.top-setion-learn{
  background-color: $whiteColor;
  padding: 65px 0px;
  // margin-top: 110px;
  position: relative;
  top: 110px;

  .title{
    font-size: 16px;
    font-weight: 600;
    line-height: 10.67px;
    color:#0171DE;
  }
  .heading{
    font-size: 50px;
    font-weight: 700;
    line-height: 52px;
    text-align: center;
    margin-top: 26px;
  }


  .search {
    // margin-right: 300px;
    position: relative;
    width: 250px;


    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
    }

    input {
      // width: 200px;
      height: 42px;
      border-radius: 25px;
      border: 0.5px solid #000000;
      padding: 0px 16px !important;
    }

    ::placeholder {
      font-weight: 700;
      text-transform: uppercase;
      color: #000000;
    }
  }

  .img-section{
    margin-top: 80px;
    height: 430px;
    background-color: #C4C8CC;

  }

  .card-sec{
    min-height: 300px;

    .top-card-section{
      top: -100px;

      .topic-title{
        font-size: 16px;
        font-weight: 590;
        line-height: 17.5px;
        letter-spacing: 0.05em;
        text-align: left;

      }

      .align-btn{
        background-color: rgba(255, 255, 255, 0.2);
        padding: 10px;
        border-radius: 15px;
        border: none;
        outline: none;
        font-size: 14px;
        font-weight: 400;
        text-align: center;

      }

      .date-time{
        font-size: 12px;
        font-style: italic;
        font-weight: 276;
        line-height: 17.5px;
        letter-spacing: 0.05em;
        text-align: left;

      }

      .learn-detail-heading{
        font-size: 24px;
        font-weight: 510;
        line-height: 24px;
        text-align: left;
      }

      .learn-detail-description{
        font-size: 14px;
        font-weight: 400;
        line-height: 17.5px;
        text-align: left;
        color: #707070;

      }
    }
  }


  .blog-wrapper{
    padding: 0px 180px;



    .toggle-btn{
      color: #1F1F1F;
      background-color: white;
      border-radius: 15px;
      border: 0.5px solid rgba(0, 0, 0, 1);
      padding: 5px 10px;
      margin:0px 0px 10px 0px;
      &:hover{
        background-color: #1F1F1F;
      }
      &:active{
        background-color: #1F1F1F;
      }
    }
    .toggle-btn-active{
      background-color: #1F1F1F;
      color: white;
      border-radius: 15px;
      padding: 5px 10px;
      margin:0px 0px 10px 0px;
    }

    .toggle-btn:hover{
      color: black;
      background-color: #D9D9D9;
      border: 0.5px solid rgba(0, 0, 0, 1);
    }

    .search-filters{
      --bs-list-group-border-color: transparent;
    }
  }

  .categories{
    font-size: 16px;
    font-weight: 400;
    line-height: 17.5px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #707070;

  }

  .card-holder{
    background-color: rgba(244, 245, 246, 1);
    border-radius: 15px;
    height: 100%;


    

    .img-holder{
      height: 190px;
      top: 175px;
      left: 81px;
      gap: 0px;
      border-radius: 15px;
      background-color: #C4C8CC;
    }

    .card-category{
      background-color: rgba(220, 223, 226, 0.6);
      color: #000000;
      padding: 5px 10px;
      border: none;
      border-radius: 15px;
      margin-right: 10px;
      font-size: 14px;

    }

    .date-time{
      font-size: 12px;
      font-style: italic;
      font-weight: 276;
      line-height: 17.5px;
      letter-spacing: 0.05em;
      text-align: left;
      color: rgba(112, 112, 112, 1);

    }

    .card-title{
      font-size: 18px;
      font-weight: 510;
      line-height: 24px;
      text-align: left;
      color: rgba(31, 31, 31, 1);
    }
    .card-description{
      font-size: 14px;
      font-weight: 400;
      line-height: 17.5px;
      text-align: left;
      color: rgba(112, 112, 112, 1);

    }

  }

  .view-more{
    padding: 16px 27.03px 16px 26px;
    border-radius: 20px;
    background-color: rgba(1, 113, 222, 1);
    font-size: 16px;
    font-weight: 700;
    line-height: 10.67px;
    letter-spacing: 0.8533331751823425px;
    text-align: center;
    color: white;
    border: none;
    outline: none;
  }

  .view-more:hover{
    border: 1px solid rgba(1, 113, 222, 1);
    color: rgba(1, 113, 222, 1);
    background-color: transparent;
  }
 

  

}


@media only screen and (max-width: 475px) {

  .top-setion-learn{
    // margin-top: 110px;
    position: relative;
    top: 110px;
    .title{
      font-size: 16px;
    }
    .heading{
      font-size: 30px;
      padding: 0px 20px;
      line-height: 34px;
    }

    .card-border{
      border-radius: 15px;
    }
    
    .img-section{
      min-height: 250px;
    }
  
    .card-sec {
      min-height: 150px;

      .top-card-section{
        .learn-detail-heading {
          font-size: 14px;
        }
      }

    }
    .blog-wrapper{
      padding: 0px;

      .card-holder{
        margin: 0px 40px;
  
      }

    }
  
    
  }
  

}



.card-holder{
  background-color: rgba(244, 245, 246, 1);
  border-radius: 15px;


  

  .img-holder{
    height: 190px;
    top: 175px;
    left: 81px;
    gap: 0px;
    border-radius: 15px;
    background-color: #C4C8CC;
  }

  .card-category{
    background-color: rgba(220, 223, 226, 0.6);
    color: #000000;
    padding: 5px 10px;
    border: none;
    border-radius: 15px;
    margin-right: 10px;
    font-size: 14px;

  }

  .date-time{
    font-size: 12px;
    font-style: italic;
    font-weight: 276;
    line-height: 17.5px;
    letter-spacing: 0.05em;
    text-align: left;
    color: rgba(112, 112, 112, 1);

  }

  .card-title{
    font-size: 18px;
    font-weight: 510;
    line-height: 24px;
    text-align: left;
    color: rgba(31, 31, 31, 1);
  }
  .card-description{
    font-size: 14px;
    font-weight: 400;
    line-height: 17.5px;
    text-align: left;
    color: rgba(112, 112, 112, 1);

  }

}
 

.sub-card-detail{
  background-color: white;

  position: relative;
  top: 145px;

  .blog-top-para{
    font-size: 16px;
    font-style: italic;
    font-weight: 276;
    line-height: 17.5px;
    letter-spacing: 0.05em;
    text-align: left;

  }

  .blog-title{
    font-size: 50px;
    font-weight: 700;
    line-height: 51.2px;
    text-align: center;
    color: #000000;

  }

  .sub-blog-title{
    font-size: 16px;
    font-style: italic;
    font-weight: 276;
    line-height: 17.5px;
    letter-spacing: 0.05em;
    text-align: center;

  }

  .img-section{
    width: 1194px;
    height: 430px;
    border-radius: 15px;
    background-color: #DCDFE2;
  }


  .detail-para{
    font-family: SF Pro;
    font-size: 16px;
    font-style: italic;
    font-weight: 276;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #707070;
    letter-spacing: 0.8px;
  }

  .detail-heading{
    font-size: 28px;
    font-weight: 700;
    line-height: 17.5px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #1F1F1F;

  }

  .detail-text{
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #1F1F1F;

  }

  .sub-head-text{
    font-size: 20px;
    font-weight: 700;
    line-height: 17.5px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #1F1F1F;

  }

  .img-sec-1{
    background-color: #DCDFE2;
    height: 350px;
    border-radius: 15px;
  }

  .img-sec-2{
    background-color: #DCDFE2;
    width: 100%;
    height: 350px;
    border-radius: 15px;
  }
}
@media only screen and (max-width: 475px) {
.sub-card-detail{
  margin-top: 0px;
}
}


















// :root {
//   --star-size: 30px;
//   --star-color: #fff;
//   --star-background: #fc0;
// }

// .learn-section {
//   background-color: $navBgColor;
//   margin-top: 100px;

//   .learn-tabs {
//     padding: $defaultPadding;

//     h1 {
//       text-transform: uppercase;
//       font-weight: 700;
//       font-size: $largerFontSize;
//     }
//   }

//   .search {
//     margin-right: 200px;
//     position: relative;

//     img {
//       position: absolute;
//       top: 50%;
//       transform: translateY(-50%);
//       right: 20px;
//     }

//     input {
//       width: 200px;
//       border-radius: 25px;
//       border: 0.5px solid #000000;
//     }

//     ::placeholder {
//       font-weight: 700;
//       text-transform: uppercase;
//       color: #000000;
//     }
//   }

//   .primary-tabs {
//     .tab {
//       border: 1px solid $backgroundColor;
//       border-radius: 25px;
//       margin-right: 20px;
//       padding: 0 2.5rem;
//       cursor: pointer;

//       p {
//         margin: 0;
//         text-transform: uppercase;
//         font-size: 1rem;
//         font-weight: 600;
//       }
//     }

//     .active {
//       background-color: $blueColor;
//       border: 1px solid $blueColor;

//       p {
//         color: $whiteColor;
//       }
//     }
//   }

//   .secondary-tabs {
//     .tab {
//       cursor: pointer;
//       margin-right: 3rem;

//       p {
//         margin: 0;
//         font-size: 1rem;
//         font-weight: 400;
//       }
//     }

//     .active {
//       border-bottom: 2px solid $blueColor;
//     }
//   }

//   .learn-videos {
//     background-color: $backgroundColor;
//     color: $whiteColor;

//     a {
//       text-decoration: none;
//       color: inherit;
//     }

//     .v-container {
//       padding: 100px;
//       flex-wrap: wrap;
//       font-size: 0.85rem;
//       font-weight: 700;
//       justify-content: center;

//       .v-content:not(:last-child) {
//         margin-right: 30px;
//       }

//       .v-content {
//         width: 30%;
//         margin-bottom: 2rem;
//         padding: 2rem;

//         .video-iframe,
//         .v-thumb {
//           width: 100%;
//           height: 275px;
//         }

//         .v-desc {
//           overflow: hidden;
//           text-overflow: ellipsis;
//           display: -webkit-box;
//           -webkit-line-clamp: 2;
//           -webkit-box-orient: vertical;
//         }

//         .v-video {
//           position: relative;
//           margin-bottom: 20px;
//         }

//         .v-duration {
//           position: absolute;
//           bottom: 0;
//           right: 10px;
//           z-index: 10;
//           background-color: $backgroundColor;
//           padding: 5px 10px;
//           font-size: 0.80rem;
//         }

//         h1 {
//           font-size: 1rem;
//           font-weight: 700;
//           letter-spacing: 1px;
//         }

//         .description {
//           overflow: hidden;
//         }
//       }
//     }

//     .v-more {
//       justify-content: center;
//       align-items: center;

//       button {
//         background-color: $blueColor;
//         border-radius: 25px;
//         border: none;
//         padding: 0.75rem 2rem;
//         font-weight: 700;
//         text-transform: uppercase;
//         letter-spacing: 2px;
//       }
//     }
//   }

//   .secondary-tabs-container {
//     .search {
//       display: none;

//       input {
//         border-radius: 10px;
//       }
//     }
//   }

//   @media only screen and (min-width: 768px) and (max-width: 1200px) {
    
//     .primary-tabs-container {
//       display: none !important;
//     }

//     .secondary-tabs-container {
//       .search {
//         display: flex;
//         margin-right: 50px;
//       }
//       .secondary-tabs {
//         width: 70%;
//         .tab:first-child {
//           display: none;
//         }
//         .tab {
//           margin-right: 2rem;
//         }
//       }
//     }

    

//     .learn-videos {
//       padding-bottom: 50px;
//       .v-container {
//         padding: 1rem;
//         .v-content:not(:last-child) {
//           margin-right: 0;
//         }
//         .v-content {
//           width: 33%;
//           padding: 1rem;
//         }
//         .v-date {
//           font-weight: 400;
//         }
//         .v-more {
//           font-weight: 400;
//         }
//       }
//     }
//   }

//   @media only screen and (max-width: 767px) {

//     .learn-tabs {

//       h1 {
//         font-size: 1.5rem;
//         font-weight: bolder;
//       }
//     }

//     .secondary-tabs-container {
//       .search {
//         display: none;
//       }
//     }

//     .search {
//       width: 100%;
//       margin: 0;

//       input {
//         border-radius: 12px;
//         width: 100%;
//       }

//       ::placeholder {
//         font-size: 0.6rem;
//         font-weight: 500;
//       }
//     }

//     .learn-videos {

//       .v-container {
//         padding: 1rem;

//         .v-content {
//           margin-right: 0;
//           width: 100%;
//           margin-bottom: 2rem;
//           padding: 1rem;

//           .v-video {
//             .v-thumb {
//               border-radius: 15px;
//             }
//           }

//           .v-desc {
//             overflow: visible;
//             -webkit-line-clamp:unset;
//           }

//           h1 {
//             font-size: 2rem;
//             font-weight: 700;
//             letter-spacing: 1px;
//             text-transform: uppercase;
//             margin-top: 20px;
//           }
//         }
//       }
//     }
//   }

// }

// @media only screen and (max-width: 767px) {
//   .learn-section {
//     margin-top: 0;
//     padding-top: 50px;
//   }
// }