.view-sample{
    border-radius: 20px;
    .get-title{
        font-size: 36px;
        font-weight: 510;
        line-height: 48.72px;
        text-align: left;
    }
    .sample-para{
        font-size: 20px;
        font-weight: 590;
        line-height: 48.72px;
        text-align: left;
        color: #919191;

    }

    .card{
        background-color: #F5F5F5;
        border: 1px solid #C0C0C0;
        color: #919191;
        border-radius: 20px !important;

        .duration{
            font-size: 20px;
            font-weight: 860;
        }
        .duration-time{
            font-size: 16px;
            font-weight: 400;
        }

        .title{
            font-size: 16px;
            font-weight: 400;
        }
        .title-cal{
            font-size: 20px;
            font-weight: 510;
        }
       
    }


    .text-para{
        font-size: 14px;
        font-weight: 400;
        color: #848484;

    }

    .continue-btn{
        width: 383px;
        height: 71px;
        border-radius: 20px;
        background-color: #0071DE;
        color: #fff;
        font-size: 20px;
        font-weight: 860;
        line-height: 48.72px;
    }
    .continue-btn:hover{
        background-color: #0067CB;
    }
}

.common-popup-section{
    width: 50%;

    .close-icon{
        cursor: pointer;
    }
    .select-para{
        font-size: 14px;
        font-weight: 400;
    }
    label{
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
    }

    .checkbox-input{
        width: 19px;
        height: 19px;
    }
    .email-input{
        height: 50px;
    }
    .email-input:focus{
        box-shadow: none;
    }

    .continue-btn{
        background-color: rgb(1, 113, 222);
        height: 50px;
        border-radius: 30px;
        font-size: 20px;
    }
}

@media only screen and (max-width: 475px) {
    .common-popup-section{
        width: 100%;
    }
}